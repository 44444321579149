/*---------------------------
| Fonts
---------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

/*---------------------------
| Foundational
---------------------------*/
html,
body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-stretch: normal;
    line-height: initial;
}
body {
    overflow-y: scroll;

    &.modal-open {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 1;
    }
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 20px;
    color: #014444;
}

p {
    font-size: 16px;
    line-height: 150%;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.inset {
    padding: 0;
    width: 100%;
    margin: auto;
}

// .Main .inset {
//     min-height: auto;
// }

nav.sublinks {
    display: flex;
    justify-content: flex-start;
    margin: 30px 0px;
    a {
        margin-right: 5px;
        display: block;
        line-height: 20px;
        text-decoration: none;
        border-radius: 5px;
        padding: 10px 20px;
        color: white;
        background-color: #027272;
    }

    a:hover,
    a.active {
        background-color: #014444;
    }
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    cursor: not-allowed;
    pointer-events: auto;
}
